import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    user_id: '',
    user_first_name: '',
    user_last_name: '',
    user_status_name: '',
    user_title: '',
    user_gender: '',
    user_dob: '',
    user_email: '',
    user_phone_number: '',
    user_phone_code: '',
    user_id_type: '',
    user_id_number: '',
    authority: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        appendUserData: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser, appendUserData } = userSlice.actions

export default userSlice.reducer
