import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/v1/user/auth/login',
        method: 'post',
        data,
    })
}

export async function apiAdminSignIn(data) {
    return ApiService.fetchData({
        url: '/v1/admin/auth/login',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/v1/user/auth/signup',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/v1/user/auth/logout',
        method: 'post',
        data,
    })
}

export async function apiAdminSignOut(data) {
    return ApiService.fetchData({
        url: '/v1/admin/auth/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/v1/user/auth/forgotPassword',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/v1/user/auth/resetPassword',
        method: 'post',
        data,
    })
}

export async function apiAdminResetPassword(data) {
    return ApiService.fetchData({
        url: '/v1/admin/auth/resetPassword',
        method: 'post',
        data,
    })
}

export async function apiGetSignUpData(data){
    return ApiService.fetchData({
        url: '/v1/user/information',
        method: 'get',
        data,
    })
}

export async function apiVerifyEmail(data){
    return ApiService.fetchData({
        url: '/v1/user/verify',
        method: 'post',
        data,
    })
}

export async function apiResendVerifyEmail(data){
    return ApiService.fetchData({
        url: '/v1/user/resendInvitationEmail',
        method: 'post',
        data,
    })
}

export async function apiGetUserInformation(data){
    return ApiService.fetchData({
        url: '/v1/user',
        method: 'get',
        data,
    })
}

