const appConfig = {
    apiPrefix: 'https://staging-api.fpgtrading.com',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
